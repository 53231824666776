import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import * as Markdown from "react-markdown";
import Button from "./atoms/Button";
import HeroRatingStars from "./hero-rating-stars";
import Slider from "react-slick";
import { ChevronLeft } from "react-feather";
import { ArrowRight, Award, Banknote, CheckIcon, ChevronRight, SearchCheck, Shield, Star } from "lucide-react";
import ContactForm from "./ContactForm";
import { USAICON } from "./Navbar";
import { RadioGroup } from "@headlessui/react";
import ReviewSitesInlineRatings from "./ReviewSitesInlineRatings";

import { FadeIn, FadeInStagger } from "./FadeIn";

const FORMOFFSETPX = 350;
const services = [
  { id: "7", title: "Roofing Repair", icon: "/assets/roofing.svg" },
  { id: "8", title: "Roofing Replacement", icon: "/assets/roofing.svg" },
  { id: "9", title: "Roofing Inspection", icon: "/assets/roofing.svg" },
];
const googleIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className=" w-6 h-6">
    <path
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
      fill="#4285F4"
    />
    <path
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
      fill="#34A853"
    />
    <path
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
      fill="#FBBC05"
    />
    <path
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
      fill="#EA4335"
    />
    <path d="M1 1h22v22H1z" fill="none" />
  </svg>
);

const angiesListIcon = (
  <svg viewBox="0 0 18.09 23.74" xmlns="http://www.w3.org/2000/svg" className="text-orange-600 w-6 h-6">
    <g id="c">
      <path
        d="m14.52,0h-5.38l-1.84,11.08s-.08,0-.12.01C3.12,11.62-.03,14.92,0,18.62c.02,1.55.56,2.75,1.54,3.74.88.89,2.09,1.38,3.31,1.38.48,0,.95-.07,1.41-.23,2.19-.73,2.92-2.5,3.26-4.13,0,0,0,0,0,0,.1-1.81-.91-3.61-2.82-4.67l-.61,3.69c-.19,1.05-.45,1.67-.99,1.86-.32.11-.72,0-.99-.28-.35-.35-.55-.74-.56-1.41-.02-1.85,1.49-3.3,3.15-3.87.52-.18,1.06-.27,1.67-.27s1.26.11,1.85.31c2.24.51,3.6,2.81,3.94,5.3l.2,1.39h3.73L14.52,0Zm-3.83,11.32l1.07-7.93h.13l1.19,8.85c-.76-.4-1.57-.71-2.4-.92Z"
        fill="currentColor"
      ></path>
      <path
        d="m9.52,19.39c.19-.78.62-3.75.7-4.63-.59-.19-1.24-.31-1.85-.31s-1.15.09-1.67.27c1.92,1.06,2.92,2.85,2.82,4.67Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

const yelpIcon = (
  <svg version="1.1" className="text-brand-600 w-6 h-6" viewBox="0 0 228.097 228.097">
    <g>
      <path
        fill="currentColor"
        d="M173.22,68.06c8.204,6.784,30.709,25.392,27.042,38.455c-1.696,5.867-8.434,7.746-13.43,9.579
		c-11.505,4.171-23.33,7.471-35.339,9.9c-9.717,1.971-30.48,6.279-26.63-10.909c1.512-6.646,6.875-12.284,11.184-17.28
		c8.846-10.404,17.876-21.405,28.555-29.93c0.871-0.688,1.925-0.871,2.842-0.733C169.232,66.41,171.386,66.502,173.22,68.06z"
      />
      <path
        fill="currentColor"
        d="M161.119,205.197c-7.196-5.821-12.284-14.942-16.684-22.917c-4.309-7.7-11.092-17.876-12.238-26.813
		c-2.337-18.38,24.292-7.333,31.947-4.675c10.175,3.575,37.447,7.517,34.422,23.421c-2.521,12.971-18.151,28.784-31.213,30.801
		c-0.137,0.046-0.321,0-0.504,0c-0.046,0.046-0.092,0.092-0.137,0.137c-0.367,0.183-0.779,0.413-1.192,0.596
		C163.961,206.573,162.449,206.252,161.119,205.197z"
      />
      <path
        fill="currentColor"
        d="M101.58,157.896c14.484-6.004,15.813,10.175,15.721,19.984c-0.137,11.688-0.504,23.421-1.375,35.063
		c-0.321,4.721-0.137,10.405-4.629,13.384c-5.546,3.667-16.225,0.779-21.955-1.008c-0.183-0.092-0.367-0.183-0.55-0.229
		c-12.054-2.108-26.767-7.654-28.188-18.792c-0.138-1.283,0.367-2.429,1.146-3.3c0.367-0.688,0.733-1.329,1.146-1.925
		c1.788-2.475,3.85-4.675,5.913-6.921c3.483-5.179,7.242-10.175,11.229-14.988C85.813,172.197,92.917,161.471,101.58,157.896z"
      />
      <path
        fill="currentColor"
        d="M103.689,107.661c-21.13-17.371-41.71-44.276-52.344-69.164
		c-8.113-18.93,12.513-30.48,28.417-35.705c21.451-7.059,29.976-0.917,32.13,20.534c1.788,18.471,2.613,37.08,2.475,55.643
		c-0.046,7.838,2.154,20.488-2.429,27.547c0.733,2.888-3.621,4.95-6.096,2.979c-0.367-0.275-0.733-0.642-1.146-0.963
		C104.33,108.303,104.009,108.028,103.689,107.661z"
      />
      <path
        fill="currentColor"
        d="M101.397,134.566c1.696,7.517-3.621,10.542-9.854,13.384c-11.092,4.996-22.734,8.984-34.422,12.284
		c-6.784,1.879-17.188,6.371-23.742,1.375c-4.95-3.758-5.271-11.596-5.729-17.28c-1.008-12.696,0.917-42.993,18.517-44.276
		c8.617-0.596,19.388,7.104,26.447,11.138c9.396,5.409,19.48,11.596,26.492,20.076C100.159,131.862,101.03,132.916,101.397,134.566z
		"
      />
    </g>
  </svg>
);
const SliderContainer = styled.div`
  &:hover {
    .PrevNextArrowsContainer {
      opacity: 1 !important;
    }
  }
  .PrevNextArrowsContainer {
    opacity: 0.1;
    background-color: rgba(204, 204, 204, 0.4);
    &:hover {
      background-color: rgba(204, 204, 204, 0.9);
    }
  }
  &.server-rendered {
    .slick-track {
      left: 0% !important;
    }
  }
`;
const PrevNextArrowsContainer = styled.div`
  position: absolute;
  z-index: 1;
  @media (max-width: 767px) {
    top: calc(50% - 15px);
    height: 30px;
    width: 30px;
    padding: 5px;
  }
  top: calc(50% - 25px);
  height: 50px;
  width: 50px;

  transition: 0.2s ease all;
  color: #333;
  border-radius: 50%;
  display: flex;
  align-items: center;
  text-align: center;
  cursor: pointer;
  justify-content: center;
  &.carousel-icon-right {
    right: 18px;
  }
  &.carousel-icon-left {
    left: 18px;
  }
`;

const NextArrow = (props) => {
  const { onClick } = props;

  //auto scroll
  //setInterval(() => onClick(), 10000)

  return (
    <PrevNextArrowsContainer className="icon-container carousel-icon-left PrevNextArrowsContainer" onClick={onClick}>
      <ChevronLeft size={26} />
    </PrevNextArrowsContainer>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <PrevNextArrowsContainer className="icon-container carousel-icon-right PrevNextArrowsContainer" onClick={onClick}>
      <ChevronRight size={26} />
    </PrevNextArrowsContainer>
  );
};

const wrapTextWithSpan = (inputStr) => {
  const regex = /\*(.*?)\*/g;
  const replacedStr = inputStr.replace(regex, '<span class="text-brand-600">$1</span>');
  return replacedStr;
};

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 7000,
  pauseOnHover: false,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  fade: true,
  cssEase: "linear",
  className: "disable-interaction-on-non-active relative",
  nextArrow: <PrevArrow />,
  prevArrow: <NextArrow />,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const OptionButton = ({ checked, active, type }) => {
  return (
    <>
      <span className="flex-1">
        <span className="flex items-center">
          <img src={type.icon} alt={type.title} className="h-12 w-12 p-1  group-hover:scale-105 transition" />
          <span className="font-display ml-4  text-center block text-sm  font-medium text-gray-700 group-hover:text-black">
            {type.title}
          </span>
        </span>
      </span>
      <CheckIcon
        className={classNames(!checked ? "invisible" : "", "h-5 w-5 text-brand-600", "absolute right-2")}
        aria-hidden="true"
      />
      <span
        className={classNames(
          active ? "border" : "border-2",
          checked ? "border-brand-600" : "border-transparent",
          "pointer-events-none absolute -inset-px rounded-lg"
        )}
        aria-hidden="true"
      />
    </>
  );
};

const HeroContent = ({
  heroTitle,
  heroImage,
  description,
  primaryActionBtnLabel,
  primaryActionBtnLink,
  style,
  alt,
  preloadImage,
  hideImage,
  showForm,
}) => {
  const [selectedService, setselectedService] = useState(services[0]);
  const [step, setStep] = useState(1);

  return (
    <div className="bg-white max-w-8xl mx-auto">
      <section className="bg-neutral-100 relative">
        {/* <div className='Block-background HeroImageBlock-background u-bgColor--transparentOrange absolute right-0 top-0 bottom-20 bg-brand-50' style={{ zIndex: 0, width: '60%' }} /> */}
        {heroImage.file && heroImage.file.contentType && heroImage.file.contentType == "video/mp4" && (
          <video
            playsInline={true}
            autoPlay={true}
            muted={true}
            loop={true}
            className="!object-cover	absolute top-0 left-0 w-full h-full max-w-none absolute-important "
          >
            <source src={heroImage.file.url} type={heroImage.file.contentType} className="" />
          </video>
        )}
        <GatsbyImage
          image={getImage(heroImage)}
          alt={alt}
          loading={preloadImage}
          //  style={{clipPath: 'ellipse(52% 110% at 100% 50%)' }}
          className="absolute top-0 left-0 w-full max-w-none h-full absolute-important  "
        />{" "}
        {/* <div
        className="absolute  top-0 left-0 w-full h-full bg-[linear-gradient(180deg,whitesmoke_25%,transparent_65%)] md:bg-[linear-gradient(90deg,whitesmoke_35%,transparent_75%)]
       "
      /> */}
        <div className="absolute  top-0 left-0 w-full h-full opacity-[70%] md:opacity-[70%] bg-gradient-to-b md:bg-gradient-to-r from-black to-transparent" />
        {/* <div className="absolute  top-0 left-0 w-full h-full opacity-25 md:opacity-0 bg-gradient-to-b from-neutral-800 to-transparent" /> */}
        <div className="max-w-7xl flex flex-wrap md:flex-nowrap items-center items-stretch mx-auto heroImageBlock-home-container z-1 py-8 md:py-16">
          <div className="w-full md:w-1/2  px-6 md:px-8 mb-12 md:mb-0 py-12 md:py-20 pb-6 shrink-0 z-1 relative">
            {/* <span className="mb-4 flex items-center text-center md:text-left font-medium text-neutral-700 italic">
            <USAICON />
           Serving communities since 2004
          </span> */}
            {/* <span className='  text-neutral-700 italic rounded-full mb-4 font-medium  inline-block  '>Top Rated Premier Roofing Contractor</span> */}
            {/* <h1 className="font-display font-extrabold text-3xl sm:text-3xl md:text-5xl text-white">{heroTitle}</h1> */}
        <div className="mb-8 ">
         <ReviewSitesInlineRatings invert/></div>  
            <FadeInStagger>
             
                {/* <ReviewSitesInlineRatings  invert={true} />  */}
                {/* <p className="block font-display font-bold text-brand-600  text-2xl sm:text-3xl md:text-4xl text-center md:text-left mb-2 relative tag-special">
                  Premier Roofing Contractor
                </p> */}
         
              <FadeIn>
                <h1
                  className="font-display font-extrabold text-white text-4xl sm:text-4xl md:text-5xl text-center md:text-left mb-2"
                  dangerouslySetInnerHTML={{ __html: wrapTextWithSpan(heroTitle) }}
                />
              </FadeIn>
              <FadeIn>
                <p className="pt-2 text-lg default-text-shadow md:text-xl text-neutral-100 md:leading-8 text-center md:text-left my-4">
                  {/* {description && description.description && (
              <Markdown className="feature-extended-image has-animation" children={description.description} />
            )} */}
                  {description.description}
                </p>
              </FadeIn>

              <FadeIn>
                <div className="flex justify-center md:justify-start">
                  <Button to="/services/" className="mt-4">
                    Explore Services <ChevronRight size={16} className="ml-2" />
                  </Button>
                </div>

                <div className="mt-12 lex justify-center md:justify-start">
                  <HeroRatingStars theme="light" classes="justify-center text-center md:justify-start md:text-left" centerMobile  />
                </div>
              </FadeIn>
            </FadeInStagger>
          </div>
          {showForm && (
            <>
              <div className="flex-1" />
              <div className="flex items-center w-full md:w-1/2 lg:w-1/3  px-4 md:px-0 relative z-10">
                <div className="-mb-[350px] bg-white rounded-xl p-8 pb-6 border-brand-500 md:mb-0 shadow-xl relative flex-1">
                  <div className="-m-8 bg-brand-700 mb-4 px-8 py-2 rounded-t-xl text-center">
                    {/* <p className="uppercase tracking-wide text-sm text-white font-display">Online special</p> */}
                    <h3 className="font-display font-medium py-1 text-white text-2xl font-bold">$1000 Off<span className="block text-sm font-medium">Plus Free Gutters With Roof Replacement</span>
</h3>
                  </div>

                  <div className="flex mt-2">
                    <div className="inline-block pointer-events-none text-[0.8125rem] font-semibold bg-[#FFEB3B] py-1 px-2 justiify-center mx-auto rounded">
                      Online Special
                    </div>
                  </div>
                  <h2 className="font-display font-bold text-2xl text-black mt-2 mb-1 text-center">
                    Get a Free Estimate
                  </h2>
                  <p className="font-light leading-6 text-gray-500 text-sm text-center">
                    <b>FREE </b>same day comprehensive estimates on all projects.
                  </p>
                  <div className="hidden flex items-center justify-between divide-x mt-6 border-b  pb-6 mb-4 ">
                    <div className="flex space-x-2 items-center flex-1">
                      {googleIcon}
                      <div>
                        {" "}
                        <div className={`flex items-center mb-1`}>
                          {new Array(5).fill("").map((item) => (
                            <Star fill={"#f28d24"} size={14} stroke={"0"} />
                          ))}
                        </div>
                        <p className="font-display text-xs font-semibold text-neutral-600">4.9/5 Rating</p>
                      </div>
                    </div>
                    <div className="flex space-x-2 items-center flex-1 pl-2">
                      {angiesListIcon}
                      <div>
                        {" "}
                        <div className={`flex items-center mb-1`}>
                          {new Array(5).fill("").map((item) => (
                            <Star fill={"#f28d24"} size={14} stroke={"0"} />
                          ))}
                        </div>
                        <p className="font-display text-xs font-semibold text-neutral-600">5/5 Rating</p>
                      </div>
                    </div>
                    <div className="flex space-x-2 items-center flex-1 pl-2">
                      {yelpIcon}
                      <div>
                        {" "}
                        <div className={`flex items-center mb-1`}>
                          {new Array(5).fill("").map((item) => (
                            <Star fill={"#f28d24"} size={14} stroke={"0"} />
                          ))}
                        </div>
                        <p className="font-display text-xs font-semibold text-neutral-600">4/5 Rating</p>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 hidden" aria-hidden="true">
                    {" "}
                    <div className="flex items-center">
                      <span className="block text-xs font-semibold font-display mr-2">Step {step} of 2</span>
                      <div className="flex-1 overflow-hidden rounded-full bg-gray-200">
                        <div
                          className="h-2 rounded-full bg-brand-600 transition duration-200"
                          style={{ width: `${step * 50}%` }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="  shrink-0 z-1 relative">
                    {step === 1 && (
                      <>
                        <RadioGroup
                          value={selectedService}
                          onChange={setselectedService}
                          className={"relative flex gap-x-3 sm:gap-x-6"}
                        >
                          <div className="flex-auto">
                            {/* <RadioGroup.Label className="font-display text-sm md:text-base font-semibold leading-6 text-gray-900">
                   Which services are you interested in?
                  </RadioGroup.Label> */}

                            <div className="mt-3 grid grid-cols-1 gap-y-2  sm:gap-x-4">
                              {services.map((type) => (
                                <RadioGroup.Option
                                  key={type.id}
                                  value={type}
                                  className={({ active, checked }) =>
                                    classNames(
                                      active ? "border-brand-600 ring-1 ring-brand-600" : "border-gray-300",
                                      "transition bg-gradient-to-b from-white to-white group relative flex cursor-pointer rounded-lg border p-2 shadow-sm focus:outline-none",
                                      checked && "from-brand-50"
                                    )
                                  }
                                >
                                  {({ checked, active }) => (
                                    <OptionButton checked={checked} active={active} type={type} />
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </div>
                        </RadioGroup>

                        <Button className="mt-4 w-full" onClick={() => setStep(2)}>
                          Next
                        </Button>
                        <div className={`my-3`}>
                          <p className="text-neutral-500 text-center text-xs flex items-center justify-center pb-2">
                            {" "}
                            <svg
                              className="w-4 h-4 mr-1 text-neutral-400 flex-shrink-0"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                clipRule="evenodd"
                              />
                            </svg>{" "}
                            Your privacy is important to us.
                            {/* By submitting this form, you agree to receive text messages from our business about your project. */}
                          </p>
                        </div>
                      </>
                    )}

                    {step === 2 && (
                      <>
                        <ContactForm
                          hideLabels={true}
                          grayInputBg={true}
                          showPlaceholders={true}
                          footerContained={true}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <div className="h-[350px] md:hidden" />
        <div className=" relative dotbg">
          <div className="max-w-7xl mx-auto px-4 md:px-8 py-8 ">
            <h2 className="font-display text-center font-bold mb-2 text-2xl mb-2">#1 Premier Roofing Contractor</h2>
             <div className="  relative   mt-4 gap-4 grid grid-cols-2 md:grid-cols-4 text-neutral-800">
              <div className="flex items-center space-x-2 font-display font-medium text-sm lg:text-base">
                {" "}
                <Award className="text-brand-600" />
                <p>Licensed and Insured</p>
              </div>
              <div className="flex items-center space-x-2 font-display font-medium text-sm lg:text-base">
                {" "}
                <SearchCheck className="text-brand-600" />
                <p>
                  Free Estimates<span className="hidden md:inline"> and Inspections</span>
                </p>
              </div>
              <div className="flex items-center space-x-2 font-display font-medium text-sm lg:text-base">
                {" "}
                <Shield className="text-brand-600" />
                <p>We Work With Your Insurance</p>
              </div>
              <div className="flex items-center space-x-2 font-display font-medium text-sm lg:text-base">
                {" "}
                <Banknote className="text-brand-600" />
                <p>Competitive Financing</p>
              </div>
            </div>
          </div>
        </div>
      </section>
  
    </div>
  );
};

const HeroArea = ({ heroContent, showForm }) => {
  let isMultiSlide = false;

  if (heroContent.length || heroContent.length > 2) {
    isMultiSlide = true;
  }

  const isBuildingHTML = typeof window === "undefined";

  if (Array.isArray(heroContent)) {
    heroContent = heroContent[0];
  }

  // return <HeroContent {...heroContent} preloadImage />

  if (Array.isArray(heroContent)) {
    return (
      <SliderContainer className={isBuildingHTML ? "server-rendered" : null}>
        <Slider {...settings}>
          {heroContent.map((item, index) => (
            <HeroContent {...item} alt={item.heroTitle} key={index} preloadImage={index == 0} />
          ))}
        </Slider>
      </SliderContainer>
    );
  } else {
    return <HeroContent {...heroContent} preloadImage showForm={showForm} />;
  }
};

export default HeroArea;
