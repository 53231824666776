import { Star } from "lucide-react";
import React from "react";

const googleIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className=" w-6 h-6">
    <path
      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
      fill="#4285F4"
    />
    <path
      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
      fill="#34A853"
    />
    <path
      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
      fill="#FBBC05"
    />
    <path
      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
      fill="#EA4335"
    />
    <path d="M1 1h22v22H1z" fill="none" />
  </svg>
);

const angiesListIcon = (
  <svg viewBox="0 0 18.09 23.74" xmlns="http://www.w3.org/2000/svg" className="text-orange-600 w-6 h-6">
    <g id="c">
      <path
        d="m14.52,0h-5.38l-1.84,11.08s-.08,0-.12.01C3.12,11.62-.03,14.92,0,18.62c.02,1.55.56,2.75,1.54,3.74.88.89,2.09,1.38,3.31,1.38.48,0,.95-.07,1.41-.23,2.19-.73,2.92-2.5,3.26-4.13,0,0,0,0,0,0,.1-1.81-.91-3.61-2.82-4.67l-.61,3.69c-.19,1.05-.45,1.67-.99,1.86-.32.11-.72,0-.99-.28-.35-.35-.55-.74-.56-1.41-.02-1.85,1.49-3.3,3.15-3.87.52-.18,1.06-.27,1.67-.27s1.26.11,1.85.31c2.24.51,3.6,2.81,3.94,5.3l.2,1.39h3.73L14.52,0Zm-3.83,11.32l1.07-7.93h.13l1.19,8.85c-.76-.4-1.57-.71-2.4-.92Z"
        fill="currentColor"
      ></path>
      <path
        d="m9.52,19.39c.19-.78.62-3.75.7-4.63-.59-.19-1.24-.31-1.85-.31s-1.15.09-1.67.27c1.92,1.06,2.92,2.85,2.82,4.67Z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
);

const yelpIcon = (
  <svg version="1.1" className="text-red-600 w-6 h-6" viewBox="0 0 228.097 228.097">
    <g>
      <path
        fill="currentColor"
        d="M173.22,68.06c8.204,6.784,30.709,25.392,27.042,38.455c-1.696,5.867-8.434,7.746-13.43,9.579
          c-11.505,4.171-23.33,7.471-35.339,9.9c-9.717,1.971-30.48,6.279-26.63-10.909c1.512-6.646,6.875-12.284,11.184-17.28
          c8.846-10.404,17.876-21.405,28.555-29.93c0.871-0.688,1.925-0.871,2.842-0.733C169.232,66.41,171.386,66.502,173.22,68.06z"
      />
      <path
        fill="currentColor"
        d="M161.119,205.197c-7.196-5.821-12.284-14.942-16.684-22.917c-4.309-7.7-11.092-17.876-12.238-26.813
          c-2.337-18.38,24.292-7.333,31.947-4.675c10.175,3.575,37.447,7.517,34.422,23.421c-2.521,12.971-18.151,28.784-31.213,30.801
          c-0.137,0.046-0.321,0-0.504,0c-0.046,0.046-0.092,0.092-0.137,0.137c-0.367,0.183-0.779,0.413-1.192,0.596
          C163.961,206.573,162.449,206.252,161.119,205.197z"
      />
      <path
        fill="currentColor"
        d="M101.58,157.896c14.484-6.004,15.813,10.175,15.721,19.984c-0.137,11.688-0.504,23.421-1.375,35.063
          c-0.321,4.721-0.137,10.405-4.629,13.384c-5.546,3.667-16.225,0.779-21.955-1.008c-0.183-0.092-0.367-0.183-0.55-0.229
          c-12.054-2.108-26.767-7.654-28.188-18.792c-0.138-1.283,0.367-2.429,1.146-3.3c0.367-0.688,0.733-1.329,1.146-1.925
          c1.788-2.475,3.85-4.675,5.913-6.921c3.483-5.179,7.242-10.175,11.229-14.988C85.813,172.197,92.917,161.471,101.58,157.896z"
      />
      <path
        fill="currentColor"
        d="M103.689,107.661c-21.13-17.371-41.71-44.276-52.344-69.164
          c-8.113-18.93,12.513-30.48,28.417-35.705c21.451-7.059,29.976-0.917,32.13,20.534c1.788,18.471,2.613,37.08,2.475,55.643
          c-0.046,7.838,2.154,20.488-2.429,27.547c0.733,2.888-3.621,4.95-6.096,2.979c-0.367-0.275-0.733-0.642-1.146-0.963
          C104.33,108.303,104.009,108.028,103.689,107.661z"
      />
      <path
        fill="currentColor"
        d="M101.397,134.566c1.696,7.517-3.621,10.542-9.854,13.384c-11.092,4.996-22.734,8.984-34.422,12.284
          c-6.784,1.879-17.188,6.371-23.742,1.375c-4.95-3.758-5.271-11.596-5.729-17.28c-1.008-12.696,0.917-42.993,18.517-44.276
          c8.617-0.596,19.388,7.104,26.447,11.138c9.396,5.409,19.48,11.596,26.492,20.076C100.159,131.862,101.03,132.916,101.397,134.566z
          "
      />
    </g>
  </svg>
);

function ReviewSitesInlineRatings({ invert }) {

  const textColor = invert ? "text-neutral-100" : "text-neutral-600";
  return (
    <div className={`flex items-center justify-between divide-x space-x-2 ${invert && 'divide-neutral-100/20'}`}>
      <div className="flex space-x-2 items-center flex-1">
        {googleIcon}
        <div className=" flex-shrink-0">
          {" "}
          <div className={`flex items-center mb-1`}>
            {new Array(5).fill("").map((_, i) => (
              <Star fill={"#f28d24"} size={14} stroke={"0"} key={i + 1} />
            ))}
          </div>
          <p className={`font-display text-xs font-semibold ${textColor}`}>5/5 Rating</p>
        </div>
      </div>
      <div className="flex space-x-2 items-center flex-1 pl-2">
        {angiesListIcon}
        <div>
          {" "}
          <div className={`flex items-center mb-1`}>
            {new Array(5).fill("").map((_, i) => (
              <Star fill={"#f28d24"} size={14} stroke={"0"} key={i + 10} />
            ))}
          </div>
          <p className={`font-display text-xs font-semibold ${textColor}`}>5/5 Rating</p>
        </div>
      </div>
      <div className="flex space-x-2 items-center flex-1 pl-2">
        {yelpIcon}
        <div>
          {" "}
          <div className={`flex items-center mb-1`}>
            {new Array(5).fill("").map((_, i) => (
              <Star fill={"#f28d24"} size={14} stroke={"0"} key={i + 20} />
            ))}
          </div>
          <p className={`font-display text-xs font-semibold ${textColor}`}>4/5 Rating</p>
        </div>
      </div>
    </div>
  );
}

export default ReviewSitesInlineRatings;
